import React, { createRef, useState } from "react";
import './Location.scss';
import { Datatable } from "../../../shared/datatable/Datatable";
import { locationList } from "../../../shared/settings/apiConfig";
import { Button, Row } from "react-bootstrap";
import { TextInput } from "../../../shared/form/FormInput";
import { Validators } from "../../../shared/utils/Validators";

const locationListFilters = {
    'title': { field: 'title', value: '', op: '=', 'join': 'AND' },
    'email': { field: 'email', value: '', op: '=', 'join': 'AND' },
    'contactname' : { field: 'contactname', value: '', op: '=', 'join': 'AND' }
};

export const LocationList = (props) => {

    const editAction = (id) => {
        props.history.push(`/locations/edit/${id}`);
    }

    const [config, setConfig] = useState({
        name: 'location',
        url: locationList,
        columns: [
            
            { name: 'Location Name', property: 'title', sort: true, width: '180px' },
            { name: 'Contact Name', property: 'contactname', width: '180px' },
            { name: 'Description', property: 'description', width: '180px' },
            { name: 'Email', property: 'email', width: '200px' },
            { name: 'Phone', property: 'phone', width: '100px' },
            { name: 'Status', property: 'status', width: '80px' },
            {
                name: '', property: '', columnType: 'actions', width: '100px',
                actions: {
                    header: <Button className="save-button" onClick={() => {navigateToCreateLocation()}}>+ Create</Button>,
                    columns: [
                        { 
                            type: 'edit', 
                            action: editAction
                        }
                    ]
                }
            },
        ],
        field: ["id", "title", "description", "contactname", "email", "phone", "website", "about", "city", "country", "state", "zip", "status", "timezonename", "timezoneid"],
        filter: [],
        sort: [{ "field": "id", "direction": "asc"}],
        pagination: true,
    });

    const dataTable = createRef();

    const [locationListForm, setLocationListForm] = useState({
        'title': { value: '', valid: true, error: '', validators: [] },
        'email': { value: '', valid: true, error: '', validators: [] },
        'contactname': { value: '', valid: true, error: '', validators: [] },
    })

    const navigateToCreateLocation = () => {
        props.history.push('/locations/add');
    }

    const setValue = (controlName, event) => {
        setLocationListForm(prevState => {
            return {
                ...prevState,
                [controlName]: Validators.validateFormControl(locationListForm[controlName], event.target.value)
            }
        })
    }

    const onSearch = () => {
        let filters = [];
        const allFilters = {...locationListFilters};
        const controlKeys = Object.keys(locationListForm);
        controlKeys.forEach(control => {
            if (locationListForm[control].value) {
                filters.push({...allFilters[control], value: locationListForm[control].value});
            }
        });

        if (filters.length > 0) {
            dataTable.current.searchData(filters);
        }
    };

    const onClear = () => {
        setLocationListForm(prevState => {
            const formData = {...prevState};
            const keys = Object.keys(formData);
            keys.forEach(key => {
                formData[key].value = "";
            });

            return formData;
        });

        dataTable.current.searchData([]);
    };

    return(
        <div className="location-content">
            <div className="d-flex search-container">
                <Row>
                    <TextInput name={'title'} label={'Title'} placeholder={'Title'}
                        value={locationListForm.title.value} error={locationListForm.title.error} onChange={(event) => setValue('title', event)} />
                    <TextInput name={'email'} label={'Email'} placeholder={'Email'}
                        value={locationListForm.email.value} error={locationListForm.email.error} onChange={(event) => setValue('email', event)} />
                    <TextInput name={'contactname'} label={'Contact Name'} placeholder={'Contact Name'}
                        value={locationListForm.contactname.value} error={locationListForm.contactname.error} onChange={(event) => setValue('contactname', event)} />
                </Row>
                <div className="d-flex" style={{ gap: '10px', justifyContent: 'flex-end' }}>
                    <button type="button" className="btn btn-outline-primary" onClick={onClear}>Clear All</button>
                    <button type="button" className="btn btn-primary" onClick={onSearch}>Search</button>
                </div>
            </div>
            <div style={{ padding: '15px' }}>
                <Datatable ref={dataTable} config={config} />
            </div>
        </div>
    )  
}
