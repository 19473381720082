import React, { createRef, useState } from "react";
import './Home.scss';
import '../../../shared/scss/theme.scss';
import { assessmentList } from "../../../shared/settings/apiConfig";
import moment from "moment";
import { Form } from "react-bootstrap";
import { Datatable } from "../../../shared/datatable/Datatable";


export const AssesseDahboard = (props) => {
    const [overview, setoverview] = useState([
        {
            name: "Assessments Completed",
            count: 10,
            subname: "Requires Retest:",
            subcount: 2,
            color: "#f5f1fe"
        },
        {
            name: "Assessment Currently Open",
            count: 3,
            subname: "Overdue",
            subcount: 25,
            color: "#f0f9ff"
        },
        {
            name: "Performance Rating",
            type:"rateBase",
            color: "#ffc9e2"
        }
    ])
    const [graphChart, setgraphChart] = useState([
        {
          label: 'Series 1',
          data: [[0, 1], [1, 2], [2, 4], [3, 2], [4, 7]]
        },
        {
          label: 'Series 2',
          data: [[0, 3], [1, 1], [2, 5], [3, 6], [4, 4]]
        }
      ])
    return(
        <div className="home-container">
        <div className="d-flex w-100 align-items-center ">
            <div className='   w-10 d-flex align-items-center  py-3'>
                <img className={"mx-2 w-5 h-5"} src={`${process.env.PUBLIC_URL}/static/images/dashboard.png`} alt={"editing"} />
                <h5 className='header my-1'>Overview</h5>
            </div>
            <div className='d-flex w-90 justify-content-end align-items-center'>
                <div className="border-btn" role={"button"}>Take Assessment</div>
            </div>
        </div>
        <div className="d-flex">
            {overview.map((item, index) => (
                <div className="w-100 py-3  mx-1 position-relative" style={{ backgroundColor: item?.color }}>
                    <h5 style={{ fontWeight: "bolder" }} className="py-1 px-2">{item?.name}</h5>
                    {item?.type == "rateBase" ? null :<div>
                    <h4 style={{ fontWeight: "bolder" }} className=" px-2">{item?.count}</h4>
                    <div className="d-flex">
                        <text style={{ fontWeight: "bolder" }} className="py-1 px-2">{`${item?.subname} : `} </text>
                        <text style={{ fontWeight: "bolder" }} className="primarycolor py-1 px-2">{`${item?.subcount}`} </text>
                    </div>
                    </div>}
                </div>
            ))}
        </div>
        </div>
    )

}