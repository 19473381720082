import React, { createRef, useState } from "react";
import { Button, Row } from "react-bootstrap";

import { Datatable } from "../../../shared/datatable/Datatable";
import { userList } from "../../../shared/settings/apiConfig";
import { TextInput } from "../../../shared/form/FormInput";
import { Validators } from "../../../shared/utils/Validators";

const runAssessmentFilters = {
    'firstName': { field: 'firstname', value: '', op: '=', 'join': 'AND' },
    'email': { field: 'email', value: '', op: '=', 'join': 'AND' },
};

export const ClientList = (props) => {
    
    const dataTable = createRef();

    const editAction = (id) => {
        props.history.push(`/users/edit/${id}`);
    }

    const [config, setConfig] = useState({
        name: 'user',
        url: userList,
        columns: [
            { name: 'Location Title', property: 'locationtitle', sort: true, width: '200px' },
            { name: 'First Name', property: 'firstname', sort: true, width: '250px' },
            { name: 'Last Name', property: 'lastname', sort: true, width: '250px' },
            { name: 'Email', property: 'email', sort: true, width: '200px' },
            { name: 'Mobile', property: 'mobile', sort: true, width: '250px' },
            {
                name: '', property: '', columnType: 'actions', width: '150px',
                actions: {
                    header: <Button className="save-button" onClick={() => {navigateToClientAdd()}}>+ Create</Button>,
                    columns: [
                        { 
                            type: 'edit', 
                            action: editAction
                        }
                    ]
                }
            },
        ],
        field: ["id", "locationtitle", "firstname", "lastname", "email", "mobile"],
        filter: [],
        sort: [{ "field": "id", "direction": "asc"}],
        pagination: true,
    });

    const [clientSearchForm, setClientSearchForm] = useState({
        'firstName': { value: '', valid: true, error: '', validators: [] },
        'email': { value: '', valid: true, error: '', validators: [] },
    });

    const navigateToClientAdd = () => {
        props.history.push('/users/add');
    }

    const onSearch = () => {
        let filters = [];
        const allFilters = {...runAssessmentFilters};
        const controlKeys = Object.keys(clientSearchForm);
        controlKeys.forEach(control => {
            if (clientSearchForm[control].value) {
                filters.push({...allFilters[control], value: clientSearchForm[control].value});
            }
        });

        if (filters.length > 0) {
            dataTable.current.searchData(filters);
        }
    };

    const onClear = () => {
        setClientSearchForm(prevState => {
            const formData = {...prevState};
            const keys = Object.keys(formData);
            keys.forEach(key => {
                formData[key].value = "";
            });

            return formData;
        });

        dataTable.current.searchData([]);
    };

    const setValue = (controlName, event) => {
        setClientSearchForm(prevState => {
            return {
                ...prevState,
                [controlName]: Validators.validateFormControl(clientSearchForm[controlName], event.target.value)
            }
        });
    }

    return (
        <div className="assessment-list">
            <div className="d-flex search-container" style={{}}>
                <Row>
                    <TextInput name={'firstName'} label={'First Name'} placeholder={'First Name'}
                        value={clientSearchForm.firstName.value} error={clientSearchForm.firstName.error} onChange={(event) => setValue('firstName', event)} />
                    <TextInput name={'email'} label={'Email'} placeholder={'Email'}
                        value={clientSearchForm.email.value} onChange={(event) => setValue('email', event)} />
                </Row>
                <div className="d-flex" style={{ gap: '10px', justifyContent: 'flex-end' }}>
                    <button type="button" className="btn btn-outline-primary" onClick={onClear}>Clear All</button>
                    <button type="button" className="btn btn-primary" onClick={onSearch}>Search</button>
                </div>
            </div>
            <div style={{ padding: '15px' }}>
                <Datatable ref={dataTable} config={config} />
            </div>
        </div>
    );
};
