import { Component } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import { AssesseAssessments } from "../component/features/take-assessments/AssesseAssessments";
import { TakeAssessment } from "../component/features/take-assessments/TakeAssessment";
import {  TakeAssessments } from "../component/features/take-assessments/TakeAssessments";

export class TakeAssessmentRouter extends Component {
    
    render() {
        return (
            <Switch>
                <Route exact path={"/take-assessments/"} component={AssesseAssessments} />
                <Route exact path={"/take-assessments/:code"} component={TakeAssessments} />
                <Route exact path={"/take-assessments/run-assessments/:runid"} component={TakeAssessment} />
                <Redirect to="/not-found" />
            </Switch>
        )
    }
};