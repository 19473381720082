import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {},
    reducers: {
        setUser: (state, action) => {
            return action.payload
        }
    }
});

export const refreshTokenIntervalSlice = createSlice({
    name: 'refreshTokenInterval',
    initialState: null,
    reducers: {
        setRefreshTokenInterval: (state, action) => {
            return action.payload
        }
    }
});
export const constantStore = createSlice({
    name: 'selectedmenu',
    initialState: null,
    reducers: {
        setScreenName: (state, action) => {
            return action.payload
        }
    }
});