import React from "react"
import { Button, Form, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SignInTextInput, TextInput } from '../../../shared/form/FormInput';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SignIn.scss';
import { useState } from "react";
import { Validators } from "../../../shared/utils/Validators";
export default function AssesseeLogin() {
    const [state, setstate] = useState({
        formValid: false,
        formControls: {
            email: { value: '', valid: false, error: '', validators: [Validators.required(), Validators.email()] },
            password: { value: '', valid: false, error: '', validators: [Validators.required()] }
        },
        otp: { value: '', valid: false, error: '', validators: [Validators.required(),Validators.maxLength(6)] },
        formLoading: false,
        pwdtype: "password",
        btnLoading: false,
        otptype: "password",
        otpValid: false,
        otpview:false
    })
    const onInputChange = (controlName, event) => {
        let forms = {
            ...state.formControls,
            [controlName]: Validators.validateFormControl(state.formControls[controlName], event.target.value)
        }
        setstate({
            ...state,
            formControls: forms,
            formValid: Validators.validateForm(forms),
            otpview:false
        })
    }
    const onOTPChnage = (controlName, event) => {
        let otpvalue = Validators.validateFormControl(state[controlName], event.target.value)
        setstate({
            ...state,
            [controlName]: otpvalue,
            otpValid: Validators.validateForm({ otpvalue })
        })
    }
    const getOTP = () =>{
        setstate({
            ...state,
            otpview:true
        })
    }
    return (
        <div className=" signin w-100" >
            <img style={{zIndex:-1,position:"absolute"}} class="w-100 h-100" src={`${process.env.PUBLIC_URL}/static/images/assesseebg.jpg`} alt="bg" />
            <div className='scontainer my-2'>
                <div class="align-item-center" style={{ width: "30%", textAlign: "center" }}>
                    <img class="align-self-center" src={`${process.env.PUBLIC_URL}/static/images/Logo_with_title.png`} alt="logo" height="80px" width="100px" />
                </div>

                <div className='sigincontent'>
                    <div class="shadow-lg px-5  rounded">
                        <div className='d-flex  justify-content-center align-items-center'>
                            <h3 className='heading'>Welcome back 👋</h3>
                            {/* <img class="align-self-center" src={`${process.env.PUBLIC_URL}/static/images/hi.png`} alt="logo" height="30px" width="30px" /> */}
                        </div>
                        <Form className="d-flex flex-column justify-content-center my-1">
                            <SignInTextInput type={'text'} name={'email'} label={'Email'} placeholder={'Email'}
                                value={state?.formControls.email.value} error={state?.formControls.email.error} onChange={(event) => { onInputChange('email', event); }} />
                            <SignInTextInput type={state?.pwdtype} name={'password'} label={'Password'} placeholder={'Password'}
                                value={state?.formControls.password.value} error={state?.formControls.password.error} onChange={(event) => { onInputChange('password', event); }}
                                setType={(d) => {
                                    setstate({
                                        ...state,
                                        pwdtype: d
                                    })
                                }} />
                            <div className="rowcont  justify-content-between my-1">
                                <div></div>
                                <div className="d-flex align-items-center">
                                    <Link className='d-flex' to="/forgot-password" >
                                        <small style={{ fontSize: "14px" }}>Forgot Password?</small>
                                    </Link>
                                </div>
                            </div>
                            <button type="button" className="d-flex my-2 align-items-center justify-content-center btn btn-secondary sign-btn" onClick={() => {getOTP() }} disabled={!state?.formValid}>Get OTP{state?.formLoading ? <Spinner className='ms-2' as="span" animation="border" role="status" aria-hidden="true" /> : ''}</button>
                            {state?.otpview && <div>
                                <SignInTextInput type={state?.otptype} name={'password'} label={'OTP'} placeholder={'OTP'}
                                    value={state?.otp.value} error={state?.otp.error} onChange={(event) => { onOTPChnage('otp', event); }}
                                    setType={(d) => {
                                        setstate({
                                            ...state,
                                            otptype: d == "text" ? "number" : d
                                        })
                                    }} max={5} />
                                <button type="button" className="w-100 d-flex my-2 align-items-center justify-content-center btn btn-secondary sign-btn" onClick={() => { }} disabled={!state?.otpValid}>SignIn{state?.btnLoading ? <Spinner className='ms-2' as="span" animation="border" role="status" aria-hidden="true" /> : ''}</button>
                            </div>}
                            <div className='py-3 w-100'>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>

        </div>
    )
}