import React from "react";
import { useState } from "react";
import { TextInput } from "../../../shared/form/FormInput";
import './SignIn.scss';
import { Validators } from "../../../shared/utils/Validators";
import { Link } from "react-router-dom";
import { useEffect } from "react";
export default function SignUp() {
    const [state, setState] = useState({
        formControls: {
            email: { value: '', valid: false, error: '', validators: [Validators.required(), Validators.email()] },
        },
        formValid:false
    })
    const [width, setwidth] = useState()
    useEffect(() => {
        window.addEventListener('resize', ()=>{
            setwidth(window.innerWidth)
        });
    
      return () => {
        
      }
    }, [])
    
    const onInputChange = (controlName, event) => {
        let data = {...state}
        data["formControls"][controlName] = Validators.validateFormControl(state.formControls[controlName], event.target.value)
        data["formValid"] = Validators.validateForm( data["formControls"])
       setState(data)
    }
    return (
        <div className=" signin w-100 signup">
            <div className={`flex-column d-flex h-100 ${width < 800 ?" w-100":" w-40"} p-5 justify-content-center`}>
                <img style={{maxWidth:"100px",maxHeight:"100px"}}  class="w-50 h-50m-3 " src={`${process.env.PUBLIC_URL}/static/images/Logo_with_title.png`} alt="logo" />
                <div className="py-10 w-90">
                    <text className="w-100" style={{ fontWeight: "bolder",fontSize:"30px" }}> Create your account</text>
                    <div className='d-flex '>
                        <img role={"button"} style={{maxWidth:"50px",maxHeight:"50px"}}  class="w-30 h-30 align-self-center" src={`${process.env.PUBLIC_URL}/static/images/googlef.png`} alt="logo"  />
                        <img role={"button"} style={{maxWidth:"50px",maxHeight:"50px",margin: 10 }} class="w-30 h-30 align-self-center" src={`${process.env.PUBLIC_URL}/static/images/fbf.png`} alt="logo"  />
                        <img role={"button"} style={{maxWidth:"50px",maxHeight:"50px"}} class="w-30 h-30 align-self-center" src={`${process.env.PUBLIC_URL}/static/images/applef.png`} alt="logo"  />
                    </div>
                    <div className='or-cont justify-content-start'>
                        <text className='or-text'>OR</text>
                    </div>
                    <TextInput type={'text'} name={'email'} label={'Email'} placeholder={'Email'}
                        value={state.formControls.email.value} error={state.formControls.email.error} onChange={(event) => { onInputChange('email', event); }} />
                         
                    <button type="button" className="d-flex align-items-center justify-content-center btn btn-secondary sign-btn w-100" onClick={()=>{}} disabled={!state.formValid}>Continue</button>  
                    <p className="py-2 ">Already have an account? <Link  to="/sign-in" ><span className='text-primary' style={{fontWeight:"bolder"}}> Log in</span></Link></p>     
                </div>
            </div>
            <div  className={`" d-flex flex-column h-100  justify-content-center align-items-center ${width < 800 ?" w-100":" w-50"} r`}>
                <img class="h-`100 w-100" src={`${process.env.PUBLIC_URL}/static/images/signupimg.png`} alt="logo" />
            </div>
        </div>
    )
}