import React, { useEffect, useState } from "react";
import { Tabs, Tab, Form, Button } from "react-bootstrap";
import { Stepper, Step } from 'react-form-stepper';
import { DebounceInput } from "react-debounce-input";

import { getApi, postApi, putApi } from "../../../shared/services/apiHelper";
import { takeAssessmentRunAnswerAdd, takeAssessmentRunDetail, takeAssessmentRunList, takeAssessmentRunUpdate } from "../../../shared/settings/apiConfig";

import './TakeAssessment.scss';
import moment from "moment";
import ReactQuill from "react-quill";
import ReactPaginate from "react-paginate";
const RUN_STATUS = {
    'Not Started':"Start",
    'In Progress':"Resume",
    'Completed':"Completed",
    'Expire':"Expired"
};
const RUN_STATUS_IMG = {
    'Not Started':"NOTSTARTED",
    'In Progress':"INPROGRESS",
    'Completed':"COMPLETED",
    'Expire':"EXPIRED"
};
const BOREDER_COLOR = {
    'Not Started':"border-info",
    'In Progress':"border-warning",
    'Completed':"border-success",
    'Expire':"border-danger"
};

export const TakeAssessments = (props) => {
    const [runAssessmentList, setRunAssessmentList] = useState([])
    useEffect(() => {
        let payload = {
            filter: [
                {
                    field: "code",
                    value: props.match.params.code,
                    op: "=",
                    join: "AND"
                }
            ]
        };
        postApi(takeAssessmentRunList, payload).then(response => {
            const assessmentRunListData = response.data.data;
            console.log(assessmentRunListData,"assessmentRunListData")
            setRunAssessmentList(assessmentRunListData);

           
        }).catch((error) => {
            console.log(error);
        });
    }, []);
    return(
        <div className="take-assessment-container">
            <h5 className="font-weight-bold mx-4" style={{marginTop:"15px"}}>All Assessment</h5>
            {runAssessmentList.map((assessmentData,index)=>(
                <div className="p-2">
                    <div className="border border-1 border-grey-dark m-3 d-flex shadow">
                        <div className={`w-10 p-2 d-flex justify-content-center flex-column ${BOREDER_COLOR[assessmentData?.runstatus]}`} style={{borderLeft:"10px solid"}}>
                            <div>{moment(assessmentData?.rundate).format('dddd')}</div>
                            <div>{moment(assessmentData?.rundate).format('DD MMM YYYY')}</div>
                            <div>{moment(assessmentData?.rundate).format('hh:mm a')}</div>
                        </div>
                        <div className="right-content w-80 py-2 my-2">
                            <div className="assess-title">{assessmentData?.assessmentname ? assessmentData?.assessmentname : "---"}</div>
                            <div className="assess-description">{assessmentData?.subtitle ? assessmentData?.subtitle : "---"}</div>
                            <div className="d-flex gap-3">
                                <div className="d-flex gap-2">
                                    <div className="font-weight-bold">Subject:/Topic</div>
                                    <div>Reasoning</div>
                                </div>
                                <div className="d-flex gap-2">
                                    <div className="font-weight-bold">Duration:</div>
                                    <div>{moment().format("HH:MM:SS")}</div>
                                </div>
                                {/* <div className="d-flex gap-2">
                                    <div className="font-weight-bold">Grading:</div>
                                    <div>Score</div>
                                </div> */}
                            </div>
                        </div>
                        <div className="w-10 d-flex align-items-center justify-content-center flex-column" role={"button"} onClick={()=>{

                            if(assessmentData?.runstatus !='Expire' ||assessmentData?.runstatus !=  "Completed") {
                                props.history.push(`/take-assessments/run-assessments/${assessmentData?.clientrunasessmentid}`,assessmentData);
                            }
                        }}>
                        <img className={"m-2"} height={"25px"} width={"25px"} src={`${process.env.PUBLIC_URL}/static/images/${RUN_STATUS_IMG[assessmentData?.runstatus]}.png`} alt={"runstatus"} />
                            <div>{RUN_STATUS[assessmentData?.runstatus]}</div>
                        </div>
                    </div>

                    {/* {JSON.stringify(assessmentData)} */}
                </div>
            ))}
        </div>
    )
}