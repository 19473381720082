import React, { Component } from "react";

import { Link } from 'react-router-dom';
import './Sidebar.scss';
import store from '../../redux/store';
import { actions } from "../../redux/actions";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
const menusAssessor = [
    { name: 'Dashboard', redirectTo: '/home', imgname: "dashboard" },
    { name: 'Assessments', redirectTo: '/assessments', imgname: "folder" },
    { name: 'Run Assessments', redirectTo: '/run-assessments', imgname: "runassessment" },
    { name: 'Users', redirectTo: '/users', imgname: "employee" },
    { name: 'Locations', redirectTo: '/locations', imgname: "loc" },
    { name: 'Messages', redirectTo: '/messages', imgname: "msg" },
    { name: 'Settings', redirectTo: '/settings', imgname: "setting" },
];
const menusAssesse = [
    { name: 'Dashboard', redirectTo: '/dashboard', imgname: "dashboard" },
    { name: 'Assessments', redirectTo: '/take-assessments', imgname: "folder" },
    { name: 'Messages', redirectTo: '/messages', imgname: "msg" },
    { name: 'Settings', redirectTo: '/settings', imgname: "setting" },
];
export default function Sidebar(props) {
    const loggedInUserData = useSelector((state) => { return state.user });
    console.log(loggedInUserData, "loggedInUserData")
    const [menus, setmenus] = useState(loggedInUserData?.rolename == "Assesse" ? menusAssesse : menusAssessor)
    useEffect(() => {
        menus.forEach(element => {
            if (Array.isArray(element.submenu) && element.submenu.length > 0) {
                if (props.location.pathname.includes(element.redirectTo)) {
                    element.openSubMenu = true;
                } else {
                    element.openSubMenu = element.submenu.filter(elem => { return props.location.pathname.includes(elem.redirectTo); }).length > 0 ? true : false
                }
            }
        });
        setmenus(menus)
        return () => {
        }
    }, [])
    const handleOpenSideMenu = (menuIndex) => {
        const menulist = [...menus];
        menulist.forEach((element, index) => {
            if (Array.isArray(element.submenu) && element.submenu.length > 0) {
                element.openSubMenu = menuIndex == index ? true : false;
            }
        });

        setmenus(menulist)
    };

    return (
        <div className="sidebar-box" style={{ width: props.dimensions.SIDEBAR.value + props.dimensions.SIDEBAR.measuredIn, paddingTop: props.dimensions.HEADER.value + 0.5 + props.dimensions.HEADER.measuredIn }}>
            <ul className="side-menu-container">
                {
                    menus.map((menu, index) =>
                        <li key={`menu_${index}`} className={`menu ${props.location.pathname.includes(menu.redirectTo) ? 'active-menu ' : ''}`} onClick={() => handleOpenSideMenu(index)}>
                            <Link to={menu.redirectTo} onClick={() => {
                                store.dispatch(actions.setScreenName(menu.name));
                            }}>
                                <div className="d-flex  align-items-center">
                                    <img className="w-10 h-10 mx-3" src={`${process.env.PUBLIC_URL}/static/images/${menu.imgname}${props.location.pathname.includes(menu.redirectTo) ? "w" : "g"}.png`} alt={menu.name} />
                                    <div className={`menu-title`} style={{ color: props.location.pathname.includes(menu.redirectTo) ? "#FFF" : "#585f69" }}>{menu.name}</div>
                                </div>
                                {Array.isArray(menu.submenu) && menu.submenu.length > 0 ? <i className={`fa fa-chevron-${menu.openSubMenu ? 'down' : 'right'}`}></i> : ''}
                            </Link>
                            {
                                menu.openSubMenu && menu.submenu?.map((subMenu, subMenuIndex) =>
                                    <div key={`submenu_${index}_${subMenuIndex}`} className={` sub-menu ${props.location.pathname.includes(menu.redirectTo) ? 'active-menu' : ''}`}>
                                        <Link to={subMenu.redirectTo}>{subMenu.name}</Link>
                                    </div>
                                )
                            }
                        </li>
                    )
                }
            </ul>

            <div className="d-flex align-items-center justify-content-center">
                <img className="w-80 align-self-center" src={`${process.env.PUBLIC_URL}/static/images/adspace.jpg`} alt="ad" />
            </div>
        </div>
    )
}
