import React, { Component, createRef, useState } from "react";
import './AssessmentList.scss';
import '../../../shared/scss/theme.scss';
import { postApi } from "../../../shared/services/apiHelper";
import { assessmentList } from "../../../shared/settings/apiConfig";
import moment from "moment";
import { Form, InputGroup } from "react-bootstrap";
import { Datatable } from "../../../shared/datatable/Datatable";
import InputGroupText from "react-bootstrap/esm/InputGroupText";

const option = [
    {
        name: "craft from scratch",
        link: "/assessments/create",
        icon: "craft from scratch",
    },
    {
        name: "create using AI",
        link: "",
        icon: "create using ai",
    },
    {
        name: "craft from file",
        link: "",
        icon: "Create from File",
    },
    {
        name: "craft from pool",
        link: "",
        icon: "Create from pool",
    },
]
export const AssessmentList = (props) => {

    const [search, setsearch] = useState("")

    const editAction = (id) => {
        props.history.push(`/assessments/edit/${id}`);
    }

    const customDate = (dataValue) => {
        if (dataValue) {
            let date = moment.utc(dataValue).local();
            return `${date.format('DD/MM/YYYY')}`;
        } else
            return '- - -';
    };

    const [config, setConfig] = useState({
        name: 'assessment',
        url: assessmentList,
        columns: [
            { name: '', property: '', width: '50px', columnType: 'custom', customColumn: (event) => checkBox(event) },
            { name: 'ASSESSOR', property: 'subtitle', sort: true, width: '300px' },
            { name: 'ASSESSMENT NAME', property: 'assessmentname', sort: true, width: '250px' },
            { name: 'LOCATION', property: 'location', sort: true, showTooltip: true, width: '200px' },
            { name: 'END DATE', property: 'updateddate', sort: true, width: '100px', customColumn: customDate },
            {
                name: '', property: '', columnType: 'actions', width: '50px',
                actions: {
                    header: "",
                    columns: [
                        { type: 'edit', action: editAction }
                    ]
                }
            },
        ],
        field: ['id', 'assessmentname', 'subtitle', 'description', 'createddate', 'isdeleted'],
        filter: [{ field: 'isdeleted', value: 0, op: '=', 'join': 'AND' },
    ],
        sort: [{ "field": "assessmentname", "direction": "asc" }],
        pagination: true,
    });

    const dataTable = createRef();

    /* custom checbox */
    const checkBox = (value) => {
        return (
            <div>
                <Form>
                    <Form.Check className="d-flex align-items-center justify-content-center" type="checkbox" label="" value={value?.id}/>
                </Form>
            </div>
        );
    }

    return (
        <div className="assessmet-list-container">
            <div className="d-flex w-100 align-items-center py-2">
                <div className='   w-50 d-flex align-items-center  '>
                    <img className={"mx-2 w-5 h-5"} src={`${process.env.PUBLIC_URL}/static/images/editing.png`} alt={"editing"} />
                    <h5 className='header my-1'>Create Assessments</h5>
                </div>
            </div>
            <div className="d-flex">
                {option.map((item, index) => (
                    <div className="shadow-lg align-items-center rounded W-100 py-1  my-2 mx-2" onClick={item.link ? () => { props.history.push(item.link) } : null}>
                        <div class="d-flex  justify-content-center">
                            <img className={"m-2 w-60 h-60"} src={`${process.env.PUBLIC_URL}/static/images/${item?.icon}.jpg`} alt={item?.name} />
                        </div>
                        <div className=" d-flex  justify-content-center align-items-center  ">
                            <div className="head-name">{item?.name}</div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="d-flex w-100 align-items-center py-2 px-3  ">
                <div className='  w-50 d-flex align-items-center  '>
                    <img className={"mx-2 w-5 h-5"} src={`${process.env.PUBLIC_URL}/static/images/menu.png`} alt={"menu"} />
                    <h5 className='header my-1'>Active Assessments</h5>
                </div>
                <div className='d-flex w-80 justify-content-end align-items-center'>
                    <div className="w-50 mx-3">
                        <InputGroup className="">
                            <InputGroupText className="signicon border-primary" >
                                <i class="fa fa-search text-primary" ></i>
                            </InputGroupText>
                            <span class="icon-envelope-o"></span>
                            <Form.Control className={`signinput border-primary`} size="md" type={'text'} name={"search"} placeholder={"search by assessment name"} value={search}
                                onChange={(event) => { setsearch(event.target.value) }} autoComplete="off" />
                                 <InputGroupText role={"button"} className="border border-1 border-primary  bg-primary" onClick={()=>{
                                    // setConfig(prevState => {
                                    //    let idx = prevState["filter"].findIndex(i=>i.field == 'assessmentname')
                                    //    if(idx >=0) {
                                    //     prevState["filter"][idx]["value"] = `*${search}*`
                                    //    } else {
                                    //     prevState["filter"].push({ field: 'assessmentname', value: `*${search}*`, op: 'LIKE', 'join': 'AND' })
                                    //    }
                                    //    console.log(prevState,"prevState")
                                    //     return {...prevState}
                                    // })
                                    dataTable.current?.searchData([{ field: 'assessmentname', value: `*${search}*`, op: 'LIKE', 'join': 'AND' }])
                                    // console.log(dataTable)
                                 }}>
                                    <div className="text-white">Search</div>
                                {/* <i class="fa fa-search" ></i> */}
                            </InputGroupText>
                        </InputGroup>
                    </div>
                    {/* <button style={{ backgroundColor: "white" }} className="d-flex align-items-center border border-1 border-primary py-1 rounded mx-1">
                        <img style={{ maxWidth: "30px" }} className={"mx-2 w-20 h-20"} src={`${process.env.PUBLIC_URL}/static/images/export.png`} alt={"export"} />
                        <text className="primarycolor" style={{ fontSize: "12px" }}>Export</text>
                    </button> */}
                </div>
            </div>
            <div style={{ padding: '15px' }}>
                <Datatable ref={dataTable} config={config} />
            </div>
        </div>
    );
}