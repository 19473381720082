import { configureStore } from '@reduxjs/toolkit';

import { userSlice, refreshTokenIntervalSlice, constantStore } from './reducer';

export default configureStore({
    reducer: {
        user: userSlice.reducer,
        refreshTokenInterval: refreshTokenIntervalSlice.reducer,
        selectedmenu: constantStore.reducer
    }
});
