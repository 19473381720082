export const  questionLists = [
    [
      {
        "questionname": "When was the term machine learning coined by Arthur Samuel?",
        "hint": "question",
        "questiontype": "1",
        "questiontypename": 1,
        "questionmandatory": false,
        "questionorder": 1,
        "questionuniqueid": "682813",
        "optionset": [
          {
            "isdefaultset": true,
            "options": [
              {
                "optionname": "1959",
                "score": "1"
              }
            ],
            "temp_option_name": "",
            "temp_option_score": "",
            "setnumber": 1
          },
          {
            "isdefaultset": false,
            "options": [
              {
                "optionname": "1979s",
                "score": "2"
              },
              {
                "optionname": "1939s",
                "score": "2"
              },
              {
                "optionname": "1959",
                "score": "2"
              },
              {
                "optionname": "1949s",
                "score": "2"
              },
              {
                "optionname": "1969s",
                "score": "2"
              }
            ],
            "temp_option_name": "",
            "temp_option_score": "",
            "setnumber": 2
          }
        ]
      },
      {
        "questionname": "When are self-teaching computers also used in this time period?",
        "hint": "question",
        "questiontype": "1",
        "questiontypename": 1,
        "questionmandatory": false,
        "questionorder": 2,
        "questionuniqueid": "115241",
        "optionset": [
          {
            "isdefaultset": true,
            "options": [
              {
                "optionname": "The synonym",
                "score": "2"
              }
            ],
            "temp_option_name": "",
            "temp_option_score": "",
            "setnumber": 1
          },
          {
            "isdefaultset": false,
            "options": [
              {
                "optionname": "euphemism",
                "score": "3"
              },
              {
                "optionname": "The synonym",
                "score": "3"
              },
              {
                "optionname": "abbreviation",
                "score": "3"
              },
              {
                "optionname": "synonyms",
                "score": "3"
              },
              {
                "optionname": "machine learning",
                "score": "3"
              }
            ],
            "temp_option_name": "",
            "temp_option_score": "",
            "setnumber": 2
          }
        ]
      }
    ],
    [
      {
        "questionname": "_____'s _____ of neurons interacting with one another set a groundwork for how AIs and machine learning algorithms work under nodes, or artificial neurons used by computers to communicate data",
        "hint": "question",
        "questiontype": "1",
        "questiontypename": 1,
        "questionmandatory": false,
        "questionorder": 1,
        "questionuniqueid": "484142",
        "optionset": [
          {
            "isdefaultset": true,
            "options": [
              {
                "optionname": "Hebb",
                "score": "1"
              },
              {
                "optionname": "model",
                "score": "1"
              }
            ],
            "temp_option_name": "",
            "temp_option_score": "",
            "setnumber": 1
          },
          {
            "isdefaultset": false,
            "options": [
              {
                "optionname": "Assumption",
                "score": "3"
              },
              {
                "optionname": "model",
                "score": "3"
              },
              {
                "optionname": "Gemmule",
                "score": "3"
              },
              {
                "optionname": "Hebb",
                "score": "3"
              }
            ],
            "temp_option_name": "",
            "temp_option_score": "",
            "setnumber": 2
          }
        ]
      },
      {
        "questionname": "In _____, _____ psychologist Donald Hebb published the book The Organization of Behavior, in which he introduced a theoretical neural structure formed by certain interactions among nerve cells",
        "hint": "question",
        "questiontype": "1",
        "questiontypename": 1,
        "questionmandatory": false,
        "questionorder": 2,
        "questionuniqueid": "467050",
        "optionset": [
          {
            "isdefaultset": true,
            "options": [
              {
                "optionname": "1949",
                "score": "2"
              },
              {
                "optionname": "Canadian",
                "score": "2"
              }
            ],
            "temp_option_name": "",
            "temp_option_score": "",
            "setnumber": 1
          },
          {
            "isdefaultset": false,
            "options": [
              {
                "optionname": "Bermudan",
                "score": "4"
              },
              {
                "optionname": "Canadian",
                "score": "4"
              },
              {
                "optionname": "1949",
                "score": "4"
              }
            ],
            "temp_option_name": "",
            "temp_option_score": "",
            "setnumber": 2
          }
        ]
      }
    ]
  ]