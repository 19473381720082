import { Col, Dropdown, Form, InputGroup } from "react-bootstrap";
import InputGroupText from "react-bootstrap/esm/InputGroupText";

import './FormInput.scss';
import '../../component/no-auth/signin/SignIn.scss';
import { useEffect, useState } from "react";

export const TextInput = (props) => {

    return (
        <Col className="form-control-custom" style={props.style ? props.style : {}}>
            {props?.label && <Form.Label sm="sm" htmlFor={props.name}>{props.label}</Form.Label>}
            <Form.Control {...props}  size="sm" type={props.type ? props.type : 'text'} name={props.name} placeholder={props.placeholder} value={props.value}
                onChange={(event) => { props.onChange && props.onChange(event); }} autoComplete="off" />
           {props?.error && <div className="text-danger" style={{ minHeight: '18px', fontSize: '12px', marginTop: '4px' }}>{props.error ? props.error : ''}</div>}
        </Col>
    );
};
export const SignInTextInput = (props) => {

    return (
        <Col className="form-control-custom">
            <Form.Label sm="sm" htmlFor={props.name}>{props.label}</Form.Label>
            <InputGroup className="">
                <InputGroupText className="signicon" >
                   {props.name == "email" && <i class="fa fa-envelope" ></i>}
                   {props.name == "password" && <i class="fa fa-lock"></i>}
                    </InputGroupText>
                <span class="icon-envelope-o"></span>
                <Form.Control className={`signinput ${props.name == "password" && "signinputright"}`} size="md" type={props.type ? props.type : 'text'} name={props.name} placeholder={props.placeholder} value={props.value}
                    onChange={(event) => { props.onChange && props.onChange(event); }} autoComplete="off" />
                   {props.name == "password" &&  <InputGroupText className="signiconright"
                   role={"button"}  onClick={()=>{
                        props?.setType(props.type == 'password'?"text":'password')
                   }} >
                  {props.type == 'password'?<i class="fa fa-eye-slash"></i>:<i class="fa fa-eye"></i>}
                    </InputGroupText>}
            </InputGroup>
            <div className="text-danger" style={{ minHeight: '18px', fontSize: '12px', marginTop: '4px' }}>{props.error ? props.error : ''}</div>
        </Col>
    );
};

export const SelectInput = (props) => {
    return (
        <Col className="form-control-custom">
            <Form.Label sm="sm" htmlFor={props.name}>{props.label}</Form.Label>
            <Form.Select value={props.value} defaultValue={props.value} onChange={(event) => { props.onChange && props.onChange(event); }}>
                <option value={""} disabled>Please select a option</option>
                {
                    Array.isArray(props.options) && props.options.length > 0 ?
                        props.options.map((elem) => <option value={elem.value}>{elem.option}</option>) : ''
                }
            </Form.Select>
            <div className="text-danger" style={{ minHeight: '18px', fontSize: '12px', marginTop: '4px' }}>{props.error ? props.error : ''}</div>
        </Col>
    );
};



export const DropdownInput = (props) => {
    
    const [displayText, setDisplayText] = useState(props.value);

    useEffect(() => {
        if (props.value && Array.isArray(props.options) && props.options.length > 0) {
            setDisplayText(props.options.find(elem => { return elem.id === props.value; }).value);
        }
    }, [props.value]);

    return (
        <Col className="form-control-custom" style={props.style ? props.style : {}}>
            {props.label ? <Form.Label sm="sm" htmlFor={props.name}>{props.label}</Form.Label> : '' }
            <Dropdown onSelect={(eventKey, event) => { props.onChange && props.onChange(event.target.innerText); }}>
                <Dropdown.Toggle  size="sm" variant="default" style={displayText ? {color: '#000000'} : {}}>
                    {displayText ? displayText : (props.defaultValue || "Select a value" )}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        Array.isArray(props.options) && props.options.length > 0 ? props.options.map((elem, index) => <Dropdown.Item key={index}>{elem.value}</Dropdown.Item>) : ''
                    }
                </Dropdown.Menu>
            </Dropdown>
        </Col>
    );
};

export const DropdownInputGroup = (props) => {

    const [openDropdown, setOpenDropdown] = useState(false);
    const [displayValue, setDisplayValue] = useState('');
    const [dropdownData, setDropdownData] = useState(props.data ? props.data : []);

    useEffect(() => {
        let selectedValue = [];
        dropdownData.forEach(element => {
            selectedValue = [...selectedValue, ...element.options.filter(elem => { return elem.checked; }).map(elem => { return elem.value })];
        });
        if ( selectedValue.length > 0 ) {
            setDisplayValue(selectedValue.join(', '));
        } else {
            setDisplayValue('');
        }
    }, [dropdownData]);

    const onSelectChange = (event, groupIndex, optionIndex) => {
        setDropdownData(prevState => {
            prevState[groupIndex].options[optionIndex].checked = event.target.checked;

            props.onChange && props.onChange(prevState);

            return [...prevState];
        });
    };

    const handleBlur = (event) => {
        // if ( event.relatedTarget ) {
        //     let id = event.relatedTarget.getAttribute('id');
        //     if ( typeof id === 'string' && id.includes('group-option') ) {
        //         return;
        //     }
        // }

        // setOpenDropdown(false);
    };

    return (
        <div className='form-control-custom-dropdown  w-80' tabIndex="0" onBlur={handleBlur}>
            <div className='selected-option truncate-text' style={{color: displayValue ? "#000000" : "#616161"}} onClick={() => { setOpenDropdown(!openDropdown); }}>{displayValue ? displayValue : props.placeholder}</div>
            {
                openDropdown ?
                <div className='dropdown-container'>
                    {
                        Array.isArray(dropdownData) && dropdownData.map((group, gpIndex) => 
                            <div className="p-2" key={`group-${gpIndex}`}>
                                <div className="font-weight-bold">{group.name}</div>
                                {
                                    Array.isArray(group.options) && group.options.map((option, opIndex) => 
                                        <div className="dropdown-option d-flex gap-2 p-2">
                                            <Form.Check 
                                                type="checkbox"
                                                key={`group-option-${gpIndex}-${opIndex}`}
                                                id={`group-option-${gpIndex}-${opIndex}`}
                                                name={`group-option-${gpIndex}-${opIndex}`}
                                                label={option.value}
                                                defaultChecked={option.checked ? true : false}
                                                onChange={(event) => { onSelectChange(event, gpIndex, opIndex); }} />
                                        </div>)
                                }
                            </div>)
                    }
                </div> : null
            }
        </div>
    );
};