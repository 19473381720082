import React, { Component } from 'react';

// import store from '../../redux/store';

import Header from './Header';
import Sidebar from './Sidebar';
import { AppRouter } from '../../routers/AppRouter';

import './AppLayout.scss';

const defaultDimensionConfig = {
    HEADER: { value: 4.2, measuredIn: 'rem' },
    SIDEBAR: { value: 15, measuredIn: '%' }
};

export default class Applayout extends Component {
    constructor(props){
        super(props)
        this.state = {
            path: '', 
        };
    }
    componentDidUpdate() {
        if (this.state.path !== this.props.history.location.pathname) {
            this.setState({ path: this.props.history.location.pathname });
            this.sidebarToggle();
        }
    }
    sidebarToggle = () => {
        // store.dispatch({ type: 'TOGGLE_SIDEBAR', payload: false });
    };

    render(){
        return(
            <>
                <Header {...this.props} />
                <Sidebar dimensions={defaultDimensionConfig} {...this.props} />
                <AppRouter dimensions={defaultDimensionConfig} {...this.props} />
            </>
        );
    }
}