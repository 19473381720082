
// library imports
import { Component } from 'react';

import './Datatable.scss';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { postApi } from '../services/apiHelper';

/**
 * generic table to render provided data in tabular format
 * 
 * @param config
 * 
 * @returns html code to rener table on specified api data
 * 
**/

export class Datatable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tooltipConfig: { rowIndex: null, columnIndex: null },
            loading: false,
            field: this.props.config.field,
            filter: this.props.config.filter,
            // sort: this.props.config.sort,
            pageSize: 10, // Default page size value
            rows: [], // All data is stored here
            totalRows: 0, // Total no of records
            currentPage: 1, // Default current page
            totalPages: 1, // Default max page
            previous: null,
            next: null,
            addOnfilters: []
        }
    }

    componentDidMount() {
        this.getTableData();
    }

    getRequestPayload = (previousPage = false, nextPage = false) => {
        const payload = {};

        if (Array.isArray(this.state.field) && this.state.field.length > 0) {
            payload['field'] = this.state.field;
        }

        if (Array.isArray(this.state.filter) && Array.isArray(this.state.addOnfilters)) {
            payload['filter'] = [...this.state.filter, ...this.state.addOnfilters];
        }

        // if (this.state.sort) {
        //     payload['sort'] = this.state.sort;
        // }

        if (this.props.config.pagination) {
            payload["page"] = {
                "size": this.state.pageSize
            }

            if (nextPage && this.state.next) {
                payload["page"]['next'] = this.state.next;
            } else if (previousPage && this.state.previous) {
                payload["page"]['previous'] = this.state.previous;
            }
        }

        return payload;
    };

    getTableData = (previousPage = false, nextPage = false) => {
        if (this.props.config.url) {
            this.setState({ loading: true });
            postApi(this.props.config.url, this.getRequestPayload(previousPage, nextPage)).then((response) => {
                const rows = response.data.data;
                const page = response.data.page;

                let totalRows = this.state.totalRows;
                let totalPages = this.state.totalPages;
                let previous = this.state.previous;
                let next = this.state.next;

                if (typeof page !== 'undefined') {
                    if (page.total) {
                        totalRows = page.total; // Total no of records
                        totalPages = Math.ceil(totalRows / page.size); // Calculating total pages based on total rows
                    }
                } else {
                    totalRows = rows.length;
                }

                if (page?.keyset?.previous) {
                    previous = page.keyset.previous;
                }

                if (page?.keyset?.next) {
                    next = page.keyset.next;
                }
                
                this.setState({ loading: false, rows, totalRows, totalPages, previous, next });
            }).catch(() => {
                this.setState({ loading: false });
            });
        }
    }

    showTooltip = (rowIdx, colIdx) => {
        return this.state.tooltipConfig.rowIndex == rowIdx && this.state.tooltipConfig.columnIndex == colIdx;
    };

    setTooltipConfig = (rowIdx, colIdx) => {
        this.setState({
            tooltipConfig: { rowIndex: rowIdx, columnIndex: colIdx },
        });
    };

    setPage = (pageCount) => {
        if (pageCount > -1) {
            this.setState({
                currentPage: this.state.currentPage + 1
            }, () => {
                this.getTableData(false, true);
            });
        } else {
            this.setState({
                currentPage: this.state.currentPage - 1
            }, () => {
                this.getTableData(true, false);
            });
        }
    };

    calculatePaginationSplitup = () => {
        let initial = ((this.state.currentPage - 1) * this.state.pageSize);
        let pageStart = initial + 1;
        let pageEnd;

        if (this.state.totalRows > (this.state.currentPage * this.state.pageSize)) {
            pageEnd = this.state.currentPage * this.state.pageSize;
        } else {
            pageEnd = initial + this.state.rows.length;
        }

        return `${pageStart} - ${pageEnd}`;
    }

    sort = (field) => {
        const sort = this.state.sort[0];

        if (sort.field !== field) {
            return 'fa-exchange-alt unsort-90';
        } else if (sort['direction'] === 'asc') {
            return 'fa-long-arrow-alt-up';
        } else {
            return 'fa-long-arrow-alt-down';
        }
    };

    handleSort = (column) => {
        if (column.sort) {
            let direction = '';
            if (this.state.sort[0]['field'] === column['property'] && this.state.sort[0]['direction'] === 'asc') {
                direction = 'desc'
            } else {
                direction = 'asc';
            }
            this.setState({
                sort: [{ field: column['property'], direction: direction }],
                currentPage: 1,
                next: null,
                previous: null
            }, () => {
                this.getTableData();
            });
        }
    };

    renderColumn = (row, column) => {
        if ( column['customColumn'] ) {
            return column['customColumn'](row[column['property']]);
        } else if ( column['property'] in row ) {
            return row[column['property']] ? row[column['property']] : '- - - -';
        } else if ( column?.actions?.columns && column?.actions?.columns.length > 0 ) {
            return column.actions.columns.map((elem, index) => (
                <img key={"action-" + index} src={`${process.env.PUBLIC_URL}/static/icons/table/${elem.type}.svg`} alt={elem.type} className="action-button" onClick={() => { elem.action(row.id); }} />
            ));
        }
    };

    searchData = (addOnfilters=[]) => {
        addOnfilters.forEach(element => {
            element.value = typeof element.op === 'string' && element.op.toLowerCase() === 'like' ? `*${element.value}*` : element.value
        });
        
        if (Array.isArray(addOnfilters)) {
            this.setState({
                currentPage: 1,
                next: null,
                previous: null,
                addOnfilters
            }, () => {
                this.getTableData();
            });
        }
    };

    selectAllCheckBox = () => {
        return (
            <Form>
                <Form.Check className="d-flex align-items-center justify-content-center" type="checkbox" name="selectall" value="selectall" />
            </Form>
        );
    }

    allItemsSelect = (event) => {
        this.props.allItemsSelect(event);
    }

    render() {
        return (
            <div className="w-100" >
                <div className="w-100 border border-outline shadow" style={{ overflowX: 'auto', position: 'relative', overflowY: 'hidden' }}>
                    <table className="table-box">
                        <thead className="table-head">
                            <tr className="row-height">
                                {
                                    this.props.config.columns.map((column, colIdx) =>
                                        <th key={colIdx}
                                            style={{ minWidth: column['width'], width: column['width'], color: 'black', padding: "7px" }}
                                            className={`table-heading`}>
                                            {
                                                column?.actions?.header ? column.actions.header : column['name']
                                            }
                                            {/* {
                                                column['sort'] ? <i className={`fa ${this.sort(column['property'])}`} style={{ margin: '0 4px' }}></i> : null
                                            } */}
                                            {
                                                column['customHeader'] ? column['customHeader'] === 'selectAll' ? this.selectAllCheckBox() : '' : ''
                                            }
                                        </th>
                                    )
                                }
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            {
                                !this.state.loading && this.state.rows.length > 0 ?
                                    this.state.rows.map((row, rowIdx) =>
                                        <tr key={`data_${rowIdx}`} className={`row-height row-border clickable row-bg-even`} onClick={() => { }}>
                                            {
                                                this.props.config.columns.map((column, colIdx) =>
                                                    <td key={`column_${colIdx}`}
                                                        style={{ maxWidth: column['maxWidth'] ? column['maxWidth'] : "300px", width: column['width'], padding: "7px" }}
                                                        className={`${column.class ? column.class : ''}`}
                                                        onMouseEnter={column.showTooltip ? () => { this.setTooltipConfig(rowIdx, colIdx); } : null}
                                                        onMouseLeave={column.showTooltip ? () => { this.setTooltipConfig(null, null); } : null}>
                                                        <OverlayTrigger overlay={<Tooltip>{row[column['property']]}</Tooltip>} show={row[column['property']] ? this.showTooltip(rowIdx, colIdx) : false} placement="top">
                                                            <div>{this.renderColumn(row, column)}</div>
                                                        </OverlayTrigger>
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    ) : 
                                    <tr style={{ height: '500px' }}>
                                        <td colSpan={this.props.config.columns.length}>
                                            <div className="d-flex align-items-center justify-content-center" style={{ width: '100%', height: '100%' }}>
                                                {
                                                    this.state.loading ? <img className='datatable-loader-img' src={`${process.env.PUBLIC_URL}/static/images/loader.svg`} alt="loader-icon" />
                                                    : 'No results found, please try your search again'
                                                }
                                            </div>
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>

                {
                    this.state.rows.length ?
                        <div className="d-flex flex-column flex-sm-row align-items-center justify-content-sm-between py-4" style={{ width: '100%' }}>
                            <div className="bottom-align">Showing <b>{this.calculatePaginationSplitup()}</b> of <b>{this.state.totalRows}</b> records</div>
                            <div className="d-flex m-3 m-sm-0" style={{ cursor: 'default', border: '1px solid #0000004a' }}>
                                <i className={`d-flex align-items-center justify-content-center fa fa-angle-double-left page-switch ${!this.state.loading && this.state.currentPage !== 1 ? 'switch-enable' : 'switch-disable'}`} onClick={() => !this.state.loading && this.state.currentPage !== 1 ? this.setPage(-1) : null} />
                                <div style={{ borderLeft: '1px solid #0000004a', borderRight: '1px solid #0000004a', padding: '4px 12px' }}>Page {this.state.currentPage}</div>
                                <i className={`d-flex align-items-center justify-content-center fa fa-angle-double-right page-switch ${!this.state.loading && this.state.currentPage !== this.state.totalPages ? 'switch-enable' : 'switch-disable'}`} onClick={() => !this.state.loading && this.state.currentPage !== this.state.totalPages ? this.setPage(1) : null} />
                            </div>
                        </div> :
                        null

                }
            </div>
        );
    }
}