import React, { useEffect, useState } from "react";
import { Tabs, Tab, Form, Button } from "react-bootstrap";
import { Stepper, Step } from 'react-form-stepper';
import { DebounceInput } from "react-debounce-input";

import { getApi, postApi, putApi } from "../../../shared/services/apiHelper";
import { takeAssessmentRunAnswerAdd, takeAssessmentRunDetail, takeAssessmentRunList, takeAssessmentRunUpdate } from "../../../shared/settings/apiConfig";

import './TakeAssessment.scss';
import moment from "moment";
import ReactQuill from "react-quill";
import ReactPaginate from "react-paginate";
import { Draggable } from "react-drag-reorder";

const RUN_STATUS = {
    NOTSTARTED: 'Not Started',
    INPROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    EXPIRED: 'Expired'
};

const QUESTION_TYPE = {
    SINGLEINPUT: 0,
    MULTIPLECHECKBOX: 1,
    CHECKBOX: 2,
    RADIOBUTTON: 3
};

const questionTypes = {
    0: "Descriptive",
    1: "Multi Choice",
    2: "True or False",
    3: "Descriptive",
    5: "Rating Based",
    6: "Reorder",
    7: "Choice",
    8: "radio"
}
export function RenderQuestionView({ assessment, sessionIndex, questionIndex, clickaction,questioncount }) {

    const [question, setquestion] = useState(assessment?.sections?.[sessionIndex]?.questions?.[questionIndex])
    const [answer, setanswer] = useState(null)
    useEffect(() => {
        if(questionTypes[question?.["questiontype"]] == 'Reorder') {
            setanswer(question.options)
        }
        if(questionTypes[question?.["questiontype"]] == 'Descriptive') {
            let answerset = question?.options?.[0]
            if (answerset?.answered) {
                setanswer(answerset?.singleinputanswer)
                // setanswer()
            }
        } else {
            let answerset = []
            question?.options?.map((item)=>{
                if(item?.answered) {
                    answerset.push(item?.id)
                }
            })
            setanswer(answerset)
        }
      
        setanswer(null)
    }, [])
    
    const renderAnswer = () => {
        switch (questionTypes[question?.["questiontype"]]) {
            case "Descriptive":
                return (
                    <div>
                        <div className="text-editor">
                            <ReactQuill theme="snow"
                                modules={{
                                    toolbar: [
                                        [{ size: [] }, { 'font': [] }, { 'header': [1, 2, 3, 4, 5, 6, false] }],
                                        [{ 'color': [] }, { 'background': [] }],
                                        ['bold', 'italic', 'underline'],
                                        ['strike', 'blockquote', 'code-block'],
                                        // [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        // { 'indent': '-1' }, { 'indent': '+1' }],
                                        // ['clean'],
                                    ],
                                    clipboard: {
                                        // toggle to add extra line breaks when pasting HTML:
                                        matchVisual: false,
                                    }
                                }}
                                placeholder={"Answer"}
                                value={answer} onChange={(value) => {
                                    setanswer(value)
                                }} />
                        </div>
                    </div>
                )
            case "Multi Choice":
                return (
                    <div className="d-flex flex-wrap my-2">
                        {question.options.map((item) => (
                            <div className="w-50 my-2">
                                <Form.Check custom className="d-flex gap-1 align-items-center" type="checkbox" label={item?.optionname} value={item?.id} checked={answer?.includes(item?.id)}
                                    onChange={(event) => {
                                        let val = Number(event?.target?.value)
                                        if (answer?.includes(val)) {
                                            setanswer(prevState => {
                                                prevState.splice(answer.indexOf(val), 1)
                                                if(prevState.length > 0)
                                                return [...prevState];
                                                else 
                                                return null

                                            });
                                        } else {
                                            let ans = answer == null ? [] : answer
                                            setanswer([...ans, val])
                                        }
                                    }} />
                            </div>
                        ))}
                    </div>
                )
            case "Choice":
                return (
                    <div className="d-flex flex-wrap my-2">
                        {question.options.map((item) => (
                            <div className="w-50 my-2">
                                <Form.Check custom className="d-flex gap-1 align-items-center" type="checkbox" label={item?.optionname} value={item?.id}  checked={answer == item?.id}
                                    onChange={(event) => {
                                        setanswer(event?.target?.value)
                                    }} />
                            </div>
                        ))}
                    </div>
                )
            case "Radio":
                return (
                    <div className="d-flex flex-wrap my-2">
                        {question.options.map((item) => (
                            <div className="w-50 my-2">
                                <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label={item?.optionname} value={item?.id}  checked={answer == item?.id}
                                    onChange={(event) => {
                                        setanswer(event?.target?.value)
                                    }} />
                            </div>
                        ))}
                    </div>
                )
            case "True or False":
                return (
                    <div className="d-flex flex-wrap my-2">
                        {question.options.map((item) => (
                            <div className="w-50 my-2">
                                <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label={item?.optionname} value={item?.id} checked={answer == item?.id}
                                    onChange={(event) => {
                                        setanswer(event?.target?.value)
                                    }} />
                            </div>
                        ))}
                    </div>
                )

            case "Reorder":
                return ( 
                    <Draggable className="d-flex flex-wrap my-2 flex-column" onPosChange={(i,j)=>{
                        let ans = [...answer]
                        const x = ans[i]
                        ans[i] = ans[j]
                        ans[j] = x
                        setanswer(ans)
                    }}>
                        {answer.map((item, index) => (
                            <div className="my-1 d-flex justify-content-between p-2 border bprder-1">
                                <label>{item?.optionname}</label>
                            </div>
                        ))}
                    </Draggable>
                )

            default:
                break;
        }
    }
    return (
        <div>
            <div className="question-title">

            <div style={{ overflowWrap: "break-word", wordBreak: "break-all", overflow: "hidden" }} dangerouslySetInnerHTML={{ __html: question?.questionname }} />
                {/* <label>{question?.questionname} {JSON.stringify(question)}{questionTypes[question?.["questiontype"]]}</label> */}
            </div>
            {renderAnswer()}
            <div className="d-flex mt-5 w-100 justify-content-end">
                <div className="w-100">
                    <Button disabled={questionIndex <= 0 && sessionIndex <= 0} className="btn btn-primary" onClick={() => { clickaction("Previous", question, answer) }}>Previous</Button>
                </div>
                <div className="d-flex justify-content-end mx-5 gap-2">
                    <Button disabled={answer == null} className="btn btn-primary" onClick={() => { clickaction("Next", question, answer) }}>Next</Button>
                    {question?.mandatory ? <Button className="btn btn-primary" onClick={() => { clickaction("Skip", question, answer) }}>Skip</Button>:""}
                </div>
                {/* <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={(i)=>{clickaction(i?.selected,answer)}}
                        // pageRangeDisplayed={5}
                        pageCount={questioncount.length}
                        previousLabel="< previous"
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                        forcePage={questioncount.findIndex(i=>i.sessionIndex == sessionIndex && i.questionIndex == questionIndex)}
                    /> */}
            </div>

        </div>
    )
}
export const TakeAssessment = (props) => {

    const [assessmentPage, setAssessmentPage] = useState(null);
    const [runAssessmentList, setRunAssessmentList] = useState([]);
    const [assessmentData, setAssessmentData] = useState({});
    const [sessionIndex, setsessionIndex] = useState(0)
    const [questionIndex, setquestionIndex] = useState(0)

    const [activeTab, setActiveTab] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [questioncount, setquestioncount] = useState([])
    useEffect(() => {
        setIsLoading(true);
        getApi(takeAssessmentRunDetail + props.match.params.runid).then(async(response) => {
            setIsLoading(false);
            setAssessmentPage(2);
            setActiveStep(0);
            setAssessmentData(response.data.data[0]);
            console.log(response.data.data,"Res")
            let assessmentdata = response.data.data[0]
            let assessmentquestions =[]
             await assessmentdata?.sections?.map((item,index)=>{
                item?.questions.map((data,i)=>{
                    assessmentquestions.push({ sessionIndex: index, questionIndex: i, ...data })
                })
            })
            setquestioncount(assessmentquestions)
            setsessionIndex(0)
        }).catch(error => {
            console.log(error);
        });
    }, []);


    const runAnswerAddApi = (questionId, optionId, singleInputAnswer = false, singleInputAnswerValue = null) => {
        let activeAssessment = props?.location?.state
        let payload = {
            "clientrunid": activeAssessment['clientrunid'],
            "clientrunassessmentid": activeAssessment['clientrunasessmentid'],
            "questionid": questionId
        };

        if (singleInputAnswer) {
            payload['singleinputanswer'] = singleInputAnswerValue;
        } else {
            payload['optionid'] = optionId;
        }

        postApi(takeAssessmentRunAnswerAdd, { data: payload }).then((response) => {
            let addOption = []
            if (singleInputAnswer) {
                 addOption.push({
                    ...assessmentData.sections[sessionIndex].questions[questionIndex]["options"][0],
                    singleinputanswer:singleInputAnswerValue,
                    answered: true
                 })
            } else {
                addOption =  assessmentData.sections[sessionIndex].questions[questionIndex]["options"].map((item) => {
                    if (optionId.includes(item?.id)) {
                        return ({
                            ...item,
                            answered: true
                        })
                    } else {
                        return ({
                            ...item,
                            answered: false
                        })
                    }
                })
            }
            if ('removequestions' in response.data.data && Array.isArray(response.data.data.removequestions) && response.data.data.removequestions.length > 0) {
                setAssessmentData(prevState => {
                    let data = { ...prevState };
                    let questions = data.sections[sessionIndex].questions;
                    data.sections[sessionIndex].questions[questionIndex]["options"] = addOption
                    response.data.data['removequestions'].forEach((elem) => {
                        let index = questions.findIndex((item) => { return item.id == elem; });
                        if (index > -1) {
                            questions.splice(index, 1);
                        }
                    });
                    return data;
                });
            } else if ('addquestions' in response.data.data && Array.isArray(response.data.data.addquestions) && response.data.data.addquestions.length > 0) {
                setAssessmentData(prevState => {
                    let data = { ...prevState };
                    let questions = data.sections[sessionIndex].questions;
                    data.sections[sessionIndex].questions[questionIndex]["options"] = addOption
                    response.data.data['addquestions'].forEach((elem) => {
                        let index = questions.findIndex((item) => { return item.id == elem.id; });
                        if (index < 0) {
                            questions.push(elem);
                        }
                    });

                    data.sections[sessionIndex].questions = questions;
                    return data;
                });
            } else {
                setAssessmentData(prevState => {
                    let data = { ...prevState };
                    data.sections[sessionIndex].questions[questionIndex]["options"] = addOption
                    console.log(data,"data")
                    return data;
                });
            }
        });
    };

    // const updateRunStatus = () => {
    //     let payload = {
    //         data: { clientrunid: runAssessmentList[0]['clientrunid'] }
    //     };
    //     putApi(takeAssessmentRunUpdate, payload).then(response => {
    //         getAssessmentDetailApi(runAssessmentList, response.data.data.clientrunassessmentid, true);
    //     });
    // }

    const goToPreviousSection = () => {
        setActiveStep(activeStep - 1);
    }

    const goToNextSection = () => {
        setActiveStep(activeStep + 1);
    };
    // const onsubmit = () => {
        
    // }
    const onPressBTN = (quesIndex,answer,key="") => {
        if (key == "skip") {
        } else {
            let getquestionData =questioncount[quesIndex]
            setsessionIndex(getquestionData["sessionIndex"])
            setquestionIndex(getquestionData["questionIndex"])
        }
    }
    const onPressButton = (key, question, answer) => {
        if (key != "skip") {
            if ((questionTypes[question?.["questiontype"]]) == "Descriptive" && answer != null ) {
                runAnswerAddApi(question.id, null, true, answer);
            } else if( answer.length > 0 ) {
                runAnswerAddApi(question.id, answer, false, null);
            }
        }
        // ssessment?.sections?.[sessionIndex]?.questions?.[questionIndex]
        if (key == "Previous") {
            if (questionIndex > 0) {
                setquestionIndex(questionIndex - 1)
            } else {
                if(sessionIndex > 0) {
                    setquestionIndex(assessmentData?.sections?.[sessionIndex-1]?.questions.length-1)
                    setsessionIndex(sessionIndex - 1)
                } else {
                    alert("completed")
                }
            }

        } else if (key == "Next" || (key == "Skip")) {
            if (assessmentData?.sections?.[sessionIndex]?.questions.length-1 > questionIndex) {
                setquestionIndex(questionIndex + 1)
            } else {
                if(assessmentData?.sections?.length-1 > sessionIndex) {
                    setsessionIndex(sessionIndex + 1)
                    setquestionIndex(0)
                } else {
                    alert("completed")
                }
            }
        }

    }


    const ContentSwitcher = () => {
      return(  <div className="take-assessment-container">
      <div className='  w-50 d-flex align-items-center  '>
          <img className={"mx-2 w-5 h-5"} src={`${process.env.PUBLIC_URL}/static/images/menu.png`} alt={"menu"} />
          <h5 className='header my-1'>Assessments</h5>
      </div>
      <div className="d-flex  justify-content-end mx-5 gap-5">
          <div className="d-flex gap-2">
              <div className="font-weight-bold">Subject:/Topic</div>
              <div>Reasoning</div>
          </div>
          <div className="d-flex gap-2">
              <div className="font-weight-bold">Duration:</div>
              <div>{moment().format("HH:MM:SS")}</div>
          </div>
          <div className="d-flex gap-2">
              <div className="font-weight-bold">Grading:</div>
              <div>Score</div>
          </div>
      </div>
      <div>
          <div className="assess-title">{assessmentData.assessmentname}</div>
          <div className="assess-description">{assessmentData.subtitle}</div>
      </div>
      <div className="borderui">
      </div>
      <div className="d-flex gap-2 align-items-center">
          <img className={"m-2"} height={"25px"} width={"25px"} src={`${process.env.PUBLIC_URL}/static/images/clock.png`} alt={"clock"} />
          <div className="clock">{moment().format("HH:MM:SS")} Remaining</div>
      </div>
      <div className="d-flex  justify-content-end mx-5 gap-2">
          <div className=" align-items-center d-flex flex-column">
              <img width={"30px"} height={"30px"} className="" role={"button"} src={`${process.env.PUBLIC_URL}/static/images/calculator.png`} />
              <div className="img-text">Calculator</div>
          </div>
          <div className=" align-items-center d-flex flex-column">
              <img width={"30px"} height={"30px"} className="" role={"button"} src={`${process.env.PUBLIC_URL}/static/images/attachment.png`} />
              <div className="img-text">Attach File</div>
          </div>
      </div>
      <div className="question-ui">
          <RenderQuestionView
              assessment={assessmentData}
              sessionIndex={sessionIndex}
              questionIndex={questionIndex}
              clickaction={onPressButton}
              questioncount={questioncount} />
      </div>
          {/* <div className="d-flex align-items-center justify-content-center flex-row mt-4">
              <Button variant="outline-dark btn-white mx-2" style={{ width: '120px' }} onClick={() => {
                 
              }}>Cancel</Button>
              <Button className="btn btn-primary mx-2" style={{ width: '120px' }} onClick={() => {
                 onsubmit()
              }}>Submit</Button>
          </div> */}
  </div>)
    };

    return (
        <div className="full-width-height display-flex h-center v-center">
            {
                !isLoading ? <ContentSwitcher /> : null
            }
        </div>

    );
};
// return (
//     <div className="take-assessment-container">
//         <Tabs defaultActiveKey={activeTab} id="assessment-tabs">
//             {
//                 runAssessmentList.map((assessment, index) => (
//                     <Tab key={"assessment-tab-" + index} eventKey={index} title={assessment.assessmentname} disabled={activeTab != index}>
//                         <Stepper activeStep={activeStep}>
//                             {
//                                 assessmentData.sections.map((values, stepIndex) => (
//                                     <Step key={'section-stepper-' + stepIndex} label={values.sectionname} onClick={() => { }} />
//                                 ))
//                             }
//                         </Stepper>
//                     </Tab>
//                 ))
//             }
//         </Tabs>
//         {
//             assessmentData?.sections?.[activeStep]?.questions ?
//                 <div className="question-container">
//                     {
//                         assessmentData.sections[activeStep].questions.map((question, qIndex) => (
//                             <div key={"question-" + qIndex} className="question">
//                                 <label>{question.questionname}</label>
//                                 {
//                                     question.questiontype === QUESTION_TYPE.SINGLEINPUT ?
//                                         <DebounceInput type="text" debounceTimeout={500} className="form-control" value={question.singleinputanswer ? question.singleinputanswer : ''} name={question.questiontype} onChange={(event) => { onChangeInputAnswer(event, question, null, qIndex, null); }} /> : ''
//                                 }
//                                 {
//                                     question.questiontype === QUESTION_TYPE.MULTIPLECHECKBOX ?
//                                         <Form className="option-container">
//                                             {
//                                                 question.options.map((option, oIndex) => (
//                                                     <Form.Check key={"option-" + qIndex + oIndex} className="option" type="checkbox" name={"question-" + qIndex} id={option.id} label={option.optionname} value={option.optionname} defaultChecked={option.answered ? true : false} onChange={(event) => { onChangeInputAnswer(event, question, option, qIndex, oIndex); }} />
//                                                 ))
//                                             }
//                                         </Form> : ''
//                                 }
//                                 {
//                                     question.questiontype === QUESTION_TYPE.CHECKBOX ?
//                                         <Form className="option-container">
//                                             {
//                                                 question.options.map((option, oIndex) => (
//                                                     <Form.Check key={"option-" + qIndex + oIndex} className="option" type="checkbox" name={"question-" + qIndex} id={option.id} label={option.optionname} value={option.optionname} defaultChecked={option.answered ? true : false} onChange={(event) => { onChangeInputAnswer(event, question, option, qIndex, oIndex); }} />
//                                                 ))
//                                             }
//                                         </Form> : ''
//                                 }
//                                 {
//                                     question.questiontype === QUESTION_TYPE.RADIOBUTTON ?
//                                         <Form className="option-container">
//                                             {
//                                                 question.options.map((option, oIndex) => (
//                                                     <Form.Check key={"option-" + qIndex + oIndex} className="option" type="radio" name={"question-" + qIndex} id={option.id} label={option.optionname} value={option.optionname} defaultChecked={option.answered ? true : false} onChange={(event) => { onChangeInputAnswer(event, question, option, qIndex, oIndex); }} />
//                                                 ))
//                                             }
//                                         </Form> : ''
//                                 }
//                             </div>
//                         ))
//                     }

//                     <div className="assessment-action-container">
//                         { activeStep > 0 ? <Button onClick={goToPreviousSection}>Previous Section</Button> : null }
//                         { 
//                             activeStep == assessmentData.sections.length - 1 ? 
//                             <Button onClick={updateRunStatus}>{ activeTab == runAssessmentList.length - 1 ? 'Submit Assessment' : 'Next Assessment' }</Button> : 
//                             <Button onClick={goToNextSection}>Next Section</Button>
//                         }
//                     </div>
//                 </div> : null
//         }
//     </div>
// )
